import React from "react";
import logo from "../logo.png"; // Make sure to add your logo file

function Login() {
	const handleLogin = () => {
		window.location.href = `${process.env.REACT_APP_API_URL}/auth/discord`;
	};

	return (
		<div className="flex flex-col min-h-screen bg-black text-white">
			<header className="p-4">
				<img src={logo} alt="Logo" className="h-8" />
			</header>
			<main className="flex-grow container mx-auto p-4 flex flex-col items-center justify-center text-center">
				<h1 className="text-4xl font-bold mb-8">Resoled Role Selector</h1>
				<button
					onClick={handleLogin}
					className="bg-[#7289DA] hover:bg-[#5e77d4] text-white font-bold py-2 px-4 rounded transition duration-300"
				>
					Login with Discord
				</button>
			</main>
		</div>
	);
}

export default Login;
