import React, { useEffect, useState } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import logo from "../logo.png";

function Dashboard() {
	const [roles, setRoles] = useState({});
	const [token, setToken] = useState("");
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);
	const location = useLocation();

	useEffect(() => {
		const searchParams = new URLSearchParams(location.search);
		const accessToken = searchParams.get("token");
		if (accessToken) {
			setToken(accessToken);
			fetchRoles(accessToken);
		} else {
			setLoading(false);
			setError("No access token found");
		}
	}, [location]);

	const fetchRoles = async (accessToken) => {
		try {
			const response = await axios.get(
				`${process.env.REACT_APP_API_URL}/api/roles`,
				{
					params: { token: accessToken },
				}
			);
			const groupedRoles = response.data.reduce((acc, role) => {
				if (!acc[role.category]) {
					acc[role.category] = [];
				}
				acc[role.category].push(role);
				return acc;
			}, {});
			setRoles(groupedRoles);
			setLoading(false);
		} catch (error) {
			console.error("Error fetching roles:", error);
			setError("Failed to fetch roles");
			setLoading(false);
		}
	};

	const handleRoleToggle = async (roleId, enabled) => {
		// Optimistically update the UI
		setRoles((prevRoles) => {
			const updatedRoles = { ...prevRoles };
			for (const category in updatedRoles) {
				updatedRoles[category] = updatedRoles[category].map((role) =>
					role.id === roleId ? { ...role, enabled } : role
				);
			}
			return updatedRoles;
		});

		try {
			await axios.put(`${process.env.REACT_APP_API_URL}/api/roles`, {
				token,
				roleId,
				enabled,
			});
		} catch (error) {
			console.error("Error toggling role:", error);
			// Revert the change if the API call fails
			setRoles((prevRoles) => {
				const updatedRoles = { ...prevRoles };
				for (const category in updatedRoles) {
					updatedRoles[category] = updatedRoles[category].map((role) =>
						role.id === roleId ? { ...role, enabled: !enabled } : role
					);
				}
				return updatedRoles;
			});
			alert("Failed to toggle role");
		}
	};

	const handleAddAllRoles = async (category) => {
		const rolesToAdd = roles[category].filter((role) => !role.enabled);

		// Optimistically update UI
		setRoles((prevRoles) => ({
			...prevRoles,
			[category]: prevRoles[category].map((role) => ({
				...role,
				enabled: true,
			})),
		}));

		try {
			// Make a single API call to add all roles
			await axios.put(`${process.env.REACT_APP_API_URL}/api/roles/bulk`, {
				token,
				roleIds: rolesToAdd.map((role) => role.id),
				enabled: true,
			});
		} catch (error) {
			console.error("Error adding all roles:", error);
			// Revert the change if the API call fails
			setRoles((prevRoles) => ({
				...prevRoles,
				[category]: prevRoles[category].map((role) =>
					rolesToAdd.find((r) => r.id === role.id)
						? { ...role, enabled: false }
						: role
				),
			}));
			alert("Failed to add all roles");
		}
	};

	if (loading) {
		return (
			<div className="flex flex-col min-h-screen bg-black text-white">
				<main className="flex-grow container mx-auto p-4 flex items-center justify-center">
					<div className="text-2xl">Loading...</div>
				</main>
			</div>
		);
	}

	if (error) {
		return (
			<div className="flex flex-col min-h-screen bg-black text-white">
				<main className="flex-grow container mx-auto p-4 flex items-center justify-center">
					<div className="text-2xl">Error: {error}</div>
				</main>
			</div>
		);
	}

	return (
		<div className="flex flex-col min-h-screen bg-black text-white">
			<header className="p-4">
				<img src={logo} alt="Logo" className="h-8" />
			</header>
			<main className="flex-grow container mx-auto p-4 text-center">
				<h1 className="text-4xl font-bold mb-8">Available Roles</h1>
				{Object.entries(roles).length > 0 ? (
					Object.entries(roles).map(([category, categoryRoles]) => (
						<div key={category} className="mb-8">
							<div className="flex justify-between items-center mb-4">
								<h2 className="text-2xl font-semibold capitalize">
									{category}
								</h2>
								<button
									onClick={() => handleAddAllRoles(category)}
									className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
								>
									Add All
								</button>
							</div>
							<div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
								{categoryRoles.map((role) => (
									<div
										key={role.id}
										className="bg-gradient-to-b from-[#1A1919] to-black text-white rounded-lg p-5 flex flex-col justify-between min-h-[120px]"
									>
										<h3 className="text-lg font-semibold mb-3">{role.name}</h3>
										<label className="flex items-center justify-center space-x-3 cursor-pointer">
											<div className="relative">
												<input
													type="checkbox"
													checked={role.enabled}
													onChange={() =>
														handleRoleToggle(role.id, !role.enabled)
													}
													className="sr-only"
												/>
												<div
													className={`block w-10 h-6 rounded-full ${
														role.enabled ? "bg-blue-600" : "bg-gray-600"
													}`}
												></div>
												<div
													className={`absolute left-1 top-1 bg-white w-4 h-4 rounded-full transition-transform ${
														role.enabled ? "transform translate-x-full" : ""
													}`}
												></div>
											</div>
											<span className="text-sm">
												{role.enabled ? "On" : "Off"}
											</span>
										</label>
									</div>
								))}
							</div>
						</div>
					))
				) : (
					<div>
						<p className="text-xl">No roles available</p>
						<p className="text-xl mt-4">
							Buy a membership at{" "}
							<a
								href="https://resoled.it"
								className="text-decoration-line: underline"
							>
								Resoled.it
							</a>
						</p>
					</div>
				)}
			</main>
		</div>
	);
}

export default Dashboard;
